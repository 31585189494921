import React from "react";
import { graphql } from "gatsby";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";

import {
  headline1,
  headline2,
  headline3,
  headline4,
} from "../components/headlines";
import Layout from "../components/layout";
import { Technology } from "../components/technology";
import { Body } from "../components/body";
import ContactPerson from "../components/contactPerson";
import { GatsbyImage } from "gatsby-plugin-image";
import Seo from "../components/seo";
import { CaseList } from "../components/caseList";
import { Center } from "../components/center";
import { Container } from "../components/container";
import { Facts } from "../components/facts";
import { Count } from "../components/count";
import { Quote } from "../components/quote";
import { Image } from "../components/image";

const shortcodes = {
  Body,
  Technology,
  Center,
  Container,
  Facts,
  Count,
  Quote,
  Image,
  h1: headline1,
  h2: headline2,
  h3: headline3,
  h4: headline4,
};

export default function CaseTemplate({
  data: { mdx, nicole, rene, fabio, marcel, contactPerson },
}) {
  if (mdx.frontmatter.category.some((cat) => cat === "firstspirit")) {
    contactPerson = (
      <ContactPerson
        headline="Haben wir Ihr Interesse geweckt?"
        text="Sie stehen in Ihrem Unternehmen vor einer ähnlichen Herausforderung? Teilen Sie uns noch heute Ihre Wünsche und Anforderungen mit. Unsere Experten beraten Sie gerne."
        contactEmail="firstspirit@monday-consulting.com"
        contactPhone="+49 40 22 86 842 0"
        contactName="René Zoller, Unit-Leiter FirstSpirit"
      >
        <GatsbyImage
          image={rene.childImageSharp.gatsbyImageData}
          alt="René Zoller"
        />
      </ContactPerson>
    );
  } else if (mdx.frontmatter.category.some((cat) => cat === "coremedia")) {
    contactPerson = (
      <ContactPerson
        headline="Haben wir Ihr Interesse geweckt?"
        text="Sie stehen in Ihrem Unternehmen vor einer ähnlichen Herausforderung? Teilen Sie uns noch heute Ihre Wünsche und Anforderungen mit. Unsere Experten beraten Sie gerne."
        contactEmail="coremedia@monday-consulting.com"
        contactPhone="+49 40 22 86 842 0"
        contactName="Nicole Reske, Unit-Leiterin CoreMedia"
      >
        <GatsbyImage
          image={nicole.childImageSharp.gatsbyImageData}
          alt="Nicole Reske"
        />
      </ContactPerson>
    );
  } else if (mdx.frontmatter.category.some((cat) => cat === "frontend")) {
    contactPerson = (
      <ContactPerson
        headline="Haben wir Ihr Interesse geweckt?"
        text="Sie stehen in Ihrem Unternehmen vor einer ähnlichen Herausforderung? Teilen Sie uns noch heute Ihre Wünsche und Anforderungen mit. Unsere Experten beraten Sie gerne."
        contactEmail="frontend@monday-consulting.com"
        contactPhone="+49 40 22 86 842 0"
        contactName="Fabio Baser, Unit-Leiter Frontend"
      >
        <GatsbyImage
          image={fabio.childImageSharp.gatsbyImageData}
          alt="Fabio Baser"
        />
      </ContactPerson>
    );
  } else {
    contactPerson = (
      <ContactPerson
        headline="Haben wir Ihr Interesse geweckt?"
        text="Sie stehen in Ihrem Unternehmen vor einer ähnlichen Herausforderung? Teilen Sie uns noch heute Ihre Wünsche und Anforderungen mit. Unsere Experten beraten Sie gerne."
        contactEmail="business@monday-consulting.com"
        contactPhone="+49 40 22 86 842 0"
        contactName="Marcel Scheland, Geschäftsführer"
      >
        <GatsbyImage
          image={marcel.childImageSharp.gatsbyImageData}
          alt="Marcel Scheland"
        />
      </ContactPerson>
    );
  }

  return (
    <Layout
      lang="de"
      langPath="nav.references.link"
      langPathExt={mdx.frontmatter.slug}
    >
      <Seo
        title={
          "Referenz – " +
          mdx.frontmatter.title +
          " – " +
          mdx.frontmatter.headline
        }
        description={mdx.frontmatter.description}
        keywords={mdx.frontmatter.keywords}
      />
      <div className="hero text-color-lg-white mt-4 mt-lg-0">
        <header className="hero-heading">
          <div className="container">
            <div className="row">
              <div className="col-12 mb-3 cases-detail-item">
                <h1 className="h5">{mdx.frontmatter.title}</h1>
                <h2 className="text-size-2 text-size-md-1">
                  {mdx.frontmatter.headline
                    ? mdx.frontmatter.headline
                    : mdx.frontmatter.teaser}
                </h2>
                <p className="text-size-md-3">
                  {mdx.frontmatter.subline
                    ? mdx.frontmatter.subline
                    : mdx.frontmatter.description}
                </p>
              </div>
            </div>
          </div>
        </header>
        <div className="content-container">
          <div className={`bg-gradient bg-custom-${mdx.frontmatter.slug}`}>
            <GatsbyImage
              image={mdx.frontmatter.image.childImageSharp.gatsbyImageData}
              className="hero-image"
              alt="Header Image"
            />
          </div>
        </div>
      </div>
      <div
        className="parallax-translate bg-shape-diamond"
        style={{ zIndex: -999 }}
      />

      <MDXProvider components={shortcodes}>
        <MDXRenderer>{mdx.body}</MDXRenderer>
      </MDXProvider>
      <div
        className="content-container vertical-spacing bg-monday-green bg-pattern-vertigo text-color-white text-center reveal"
        data-spacing-bottom="cases"
      >
        <div className="container pt-5 pb-3">
          <div className="row mb-3">
            <div className="col-12 d-flex flex-column align-items-center">
              <h2 className="h3 mb-3">Weitere interessante Referenzen</h2>
            </div>
          </div>
        </div>
      </div>
      <div
        id="cases"
        className="container vertical-offset reveal mb-md-6 mb-5"
        data-offset="50%"
      >
        <CaseList name={mdx.frontmatter.related} count="2" />
      </div>
      <div id="get-in-touch">{contactPerson}</div>
    </Layout>
  );
}
export const pageQuery = graphql`
  query CaseQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        description
        teaser
        headline
        slug
        subline
        keywords
        related
        category
        background
        image {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
          }
        }
      }
    }
    nicole: file(relativePath: { eq: "profiles/nicole-reske.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    rene: file(relativePath: { eq: "profiles/rene-zoller.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    marcel: file(relativePath: { eq: "profiles/marcel-scheland.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    fabio: file(relativePath: { eq: "profiles/fabio-baser.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
      }
    }
  }
`;
